import { WppActionButton, WppAvatar, WppIconMore, WppListItem, WppMenuContext } from '@wppopen/components-library-react'
import { ColDef } from 'ag-grid-community'

import { Agency } from 'types/agencies/agencies'

import { TableDataItem } from './AgencyAgGrid'

export const columnDefenitions = (
  handleEditAgencyClick: (id: string) => void,
  handleDeleteAgencyClick: (id: string, name: string) => void,
  sortAsc = true,
  sortType = 'name',
): ColDef[] => [
  {
    field: 'name',
    sortable: true,
    ...(sortType === 'name' && { sort: sortAsc ? 'asc' : 'desc' }),
    headerName: 'Name',
    flex: 1.5,
    rowDrag: false,
    cellClass: 'truncate',
  },
  {
    field: 'numberOfCategories',
    sortable: true,
    ...(sortType === 'numberOfCategories' && { sort: sortAsc ? 'asc' : 'desc' }),
    headerName: 'Knowledge Base',
    comparator: (a, b) => a - b,
    cellRenderer: ({ data }: { data: TableDataItem }) => `${data.numberOfCategories} categories`,
    flex: 1,
  },
  {
    field: 'createdAt',
    sortable: true,
    ...(sortType === 'createdAt' && { sort: sortAsc ? 'asc' : 'desc' }),
    headerName: 'Date Created',
    flex: 1,
    comparator: (a: string, b: string) => {
      return new Date(a).getTime() - new Date(b).getTime()
    },
    cellRenderer: ({ data }: { data: TableDataItem }) =>
      new Intl.DateTimeFormat('en-GB', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      }).format(new Date(data.createdAt)),
  },
  {
    field: 'createdBy',
    headerName: 'Added By',
    sortable: true,
    ...(sortType === 'createdBy' && { sort: sortAsc ? 'asc' : 'desc' }),
    comparator: (a: Agency['createdBy'], b: Agency['createdBy']) => a?.name?.localeCompare(b.name || ''),
    cellRenderer: ({ data }: { data: TableDataItem }) => (
      <div className="flex flex-row justify-start items-center gap-4 truncate">
        {data.createdBy && (
          <WppAvatar
            size="m"
            className="max-w-8 max-h-8 flex-shrink-0 flex-grow-0 flex-auto"
            withTooltip
            name={data.createdBy?.name || ''}
            src={data.user?.img || ''}
          />
        )}
        <i>{data?.createdBy?.name || ''}</i>
      </div>
    ),
    flex: 1,
  },
  {
    headerName: '',
    field: 'Actions',
    width: 100,
    cellRenderer: ({ data }: { data: TableDataItem }) => (
      <div className="flex flex-col justify-center h-full">
        <WppMenuContext
          data-context-menu
          onClick={e => {
            e.stopPropagation()
          }}
        >
          <WppActionButton
            slot="trigger-element"
            data-context-menu
            onClick={e => {
              e.stopPropagation()
            }}
          >
            <WppIconMore
              direction="horizontal"
              data-context-menu
              onClick={e => {
                e.stopPropagation()
              }}
            />
          </WppActionButton>
          <div>
            <WppListItem onWppChangeListItem={() => handleEditAgencyClick(data.id)}>
              <p slot="label">Edit</p>
            </WppListItem>
            <WppListItem onWppChangeListItem={() => handleDeleteAgencyClick(data.id, data.name)}>
              <p slot="label">Delete</p>
            </WppListItem>
          </div>
        </WppMenuContext>
      </div>
    ),
  },
]
