import { useOs } from '@wppopen/react'

import { useFetchUserPermissions } from 'api/queries/users/useFetchUserPermissions'
import { agencyRoles } from 'constants/agencyRoles'

export const useSettingsPermissions = () => {
  const { osContext } = useOs()

  const { data: userPermissions, isLoading: isLoadingPermissions } = useFetchUserPermissions({
    params: {
      account_ids: [osContext.tenant.azMeta.organizationsId],
    },
  })

  const allPermissions = userPermissions?.map(permission => permission.permissions) || []
  const allPermissionsFlat = allPermissions?.flat()
  const hasAgenciesPermission = allPermissionsFlat.includes(agencyRoles.RFI_AGENCY_MANAGE)
  const isAgenciesAdmin = allPermissionsFlat.includes(agencyRoles.RFI_AGENCIES_ADMIN)

  return { hasAgenciesPermission, isAgenciesAdmin, isLoadingPermissions }
}
