import { WppActionButton, WppIconCopy, WppTooltip } from '@wppopen/components-library-react'
import clsx from 'clsx'
import Markdown from 'react-markdown'

import stylesMain from 'assets/main.module.scss'
import { MessageType } from 'components/chat/types'

import { SummaryMessage } from '@/types/summaries/summary'

interface SummaryConversationProps {
  message: SummaryMessage
  isTaskRunning?: boolean
  handleCopyToClipboard: (text: string) => void
}

export const SummaryConversation = ({ isTaskRunning, message, handleCopyToClipboard }: SummaryConversationProps) => {
  const { type, content, status } = message
  const showCopyIcon =
    !isTaskRunning && (type === MessageType.QUESTION || (type === MessageType.ANSWER && status === 1))

  return (
    <div className="relative" data-qa-container>
      <div className={clsx(type === MessageType.QUESTION ? 'justify-end' : 'justify-start', 'flex mt-1')}>
        <div className={clsx(type === MessageType.QUESTION ? 'bg-[#E0EBFF] rounded-8 p-3' : 'flex justify-start')}>
          <Markdown
            className={clsx(
              type === MessageType.QUESTION && stylesMain.blackPMobile,
              type === MessageType.ANSWER && stylesMain.captionregular,
              // 'whitespace-pre-wrap',
            )}
          >
            {content}
          </Markdown>
        </div>
      </div>

      {showCopyIcon && (
        <div className={clsx('flex justify-end sticky right-0 bottom-2 ml-auto w-1/2 h-[38px] -mt-4')}>
          <div className={clsx('bg-white shadow-md p-1 rounded-8 flex justify-between px-[3px]')} data-qa-actions>
            <WppTooltip text="Copy">
              <WppActionButton onClick={() => handleCopyToClipboard(content!)}>
                <WppIconCopy size="m" />
              </WppActionButton>
            </WppTooltip>
          </div>
        </div>
      )}
    </div>
  )
}
