import { ApiPaginator } from 'components/ApiPaginator'
import { SettingsTabs } from 'pages/settings/Settings'
import { Agency } from 'types/agencies/agencies'

import AgencyCard from '../AgencyCard'
import { AgencyListProps } from '../types'

export const AgencyCards = ({
  agencies,
  paginator,
  handleEditAgencyClick,
  handleDeleteAgencyClick,
}: AgencyListProps) => {
  return (
    <>
      <div className="flex flex-row flex-wrap gap-y-4 gap-x-[2%]">
        {agencies.map((agency: Agency) => (
          <AgencyCard
            key={agency.id}
            {...agency}
            handleEditAgencyClick={handleEditAgencyClick}
            handleDeleteAgencyClick={handleDeleteAgencyClick}
          />
        ))}
      </div>
      <ApiPaginator paginator={paginator} viewName={SettingsTabs.AGENCIES} />
    </>
  )
}
