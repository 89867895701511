import { TRANSLATIONS } from 'utils/translations'

export type TranslationReturn = (value: string) => string

export const useTranslation = (): TranslationReturn => {
  const getText = (key: string): string => {
    if (!key.length) {
      return ''
    }
    return TRANSLATIONS[key]
  }

  return getText
}
