import { createLazyQuery, createUseQuery } from 'api/common/createUseQuery'
import { PaginatedResponse } from 'api/common/types'
import { fetchAgenciesByDomain } from 'api/fetchers/agencies/fetchAgenciesByDomain'
import { fetchAgenciesByPermissions } from 'api/fetchers/agencies/fetchAgenciesByPermissions'
import { fetchAllAgencies } from 'api/fetchers/agencies/fetchAllAgencies'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { Agency } from 'types/agencies/agencies'

export const useGetAgenciesByPermissions = createUseQuery({
  queryKey: ApiQueryKeys.AGENCIES,
  fetcher: fetchAgenciesByPermissions,
  selector: res =>
    res?.data ??
    ({ paginator: { page: 1, totalItems: 0, totalPages: 0, itemsPerPage: 0 }, data: [] } as PaginatedResponse<Agency>),
})

export const useGetAgenciesLazyByPermissions = createLazyQuery({
  queryKey: ApiQueryKeys.AGENCIES,
  fetcher: fetchAgenciesByPermissions,
  selector: res =>
    res?.data ??
    ({ paginator: { page: 1, totalItems: 0, totalPages: 0, itemsPerPage: 0 }, data: [] } as PaginatedResponse<Agency>),
})
export const useGetAgenciesByDomain = createUseQuery({
  queryKey: ApiQueryKeys.AGENCIES,
  fetcher: fetchAgenciesByDomain,
  selector: res => res?.data ?? ([] as Agency[]),
})

export const useGetAgenciesLazyByDomain = createLazyQuery({
  queryKey: ApiQueryKeys.AGENCIES,
  fetcher: fetchAgenciesByDomain,
  selector: res => res?.data ?? ([] as Agency[]),
})

export const useGetAllAgencies = createUseQuery({
  queryKey: ApiQueryKeys.AGENCIES_ALL,
  fetcher: fetchAllAgencies,
  selector: res => res?.data ?? ([] as Agency[]),
})

export const useGetAllAgenciesLazy = createLazyQuery({
  queryKey: ApiQueryKeys.AGENCIES_ALL,
  fetcher: fetchAllAgencies,
  selector: res => res?.data ?? ([] as Agency[]),
})
