// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FgeHS::part(typography){color:var(--color-font-grey)}.s6480::part(textarea):focus{background-color:#fff}.Gg089{position:relative;margin-top:-55px;z-index:2;width:calc(100% - 190px)}`, "",{"version":3,"sources":["webpack://./src/pages/agency-dashboard/EditAddAgency/editaddagency.module.scss"],"names":[],"mappings":"AAAA,yBACE,4BAAA,CAIA,6BACE,qBAAA,CAIJ,OACE,iBAAA,CACA,gBAAA,CAEA,SAAA,CACA,wBAAA","sourcesContent":[".fileUploadtype::part(typography) {\n  color: var(--color-font-grey);\n}\n\n.textArea::part(textarea) {\n  &:focus {\n    background-color: #ffffff;\n  }\n}\n\n.isEditNameInput {\n  position: relative;\n  margin-top: -55px;\n\n  z-index: 2;\n  width: calc(100% - 190px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fileUploadtype": `FgeHS`,
	"textArea": `s6480`,
	"isEditNameInput": `Gg089`
};
export default ___CSS_LOADER_EXPORT___;
