// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zxmed:hover{cursor:pointer}.zxmed::part(step-bg){width:40px}.tz2TL{height:100%;background-color:#f8f9fb}.tz2TL::part(nav-sidebar){position:relative;max-height:calc(100dvh - 2*var(--wpp-os-header-height));overflow:hidden;background-color:#f8f9fb;top:0}.gpRdQ{position:sticky;height:calc(var(--page-min-height) - .5rem);top:var(--wpp-os-header-height)}`, "",{"version":3,"sources":["webpack://./src/components/SidebarCmp/SidebarCmp.module.scss"],"names":[],"mappings":"AACE,aACE,cAAA,CAGF,sBACE,UAAA,CAIJ,OACE,WAAA,CACA,wBAAA,CAEA,0BACE,iBAAA,CACA,uDAAA,CACA,eAAA,CACA,wBAAA,CACA,KAAA,CAIJ,OACE,eAAA,CACA,2CAAA,CACA,+BAAA","sourcesContent":[".step {\n  &:hover {\n    cursor: pointer;\n  }\n\n  &::part(step-bg) {\n    width: 40px;\n  }\n}\n\n.sidebar {\n  height: 100%;\n  background-color: #f8f9fb;\n\n  &::part(nav-sidebar) {\n    position: relative;\n    max-height: calc(100dvh - 2 * var(--wpp-os-header-height));\n    overflow: hidden;\n    background-color: #f8f9fb;\n    top: 0;\n  }\n}\n\n.sidebarContainer {\n  position: sticky;\n  height: calc(var(--page-min-height) - 0.5rem); /* avoid creating vertical scrollbar */\n  top: var(--wpp-os-header-height);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"step": `zxmed`,
	"sidebar": `tz2TL`,
	"sidebarContainer": `gpRdQ`
};
export default ___CSS_LOADER_EXPORT___;
