import { DatePickerClearEventDetail, DatePickerEventDetail, SelectChangeEventDetail } from '@wppopen/components-library'
import { WppDatepickerCustomEvent, WppSelectCustomEvent } from '@wppopen/components-library/dist/types/components'
import { WppDatepicker, WppListItem, WppModal, WppSelect, WppSpinner } from '@wppopen/components-library-react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { NavigateFunction, useSearchParams } from 'react-router-dom'

import { Paginator } from 'api/common/types'
import { useGetAgencyCreators } from 'api/queries/agencies/useGetAgencyCreators'
import EmptyState from 'components/EmptyState'
import { useApiSearch } from 'hooks/useApiSearch'
import { useTranslation } from 'hooks/useTranslation'
import { AgencyFilterGroup } from 'pages/agency-dashboard/AgencyFilterGroup/AgencyFilterGroup'
import { SettingsTabs } from 'pages/settings/Settings'
import { Agency } from 'types/agencies/agencies'
import { NO_RESULTS_TITLE, NO_RESULTS_DESCRIPTION } from 'utils/translations'

import { AgencyAgGrid } from './AgencyAgGrid'
import { AgencyCards } from './AgencyCards'
import style from './agencyDashboard.module.scss'
import { AgencyDeleteModal } from './AgencyDeleteModal'
import { sortType } from './AgencyFilterGroup/types'
import { useAgencyApiSortFilters } from './useAgencyApiSortFilters'

interface PreparedFilters {
  range: Date[] | []
  createdBy: string
}

function isValidDate(date: unknown): date is Date {
  return date instanceof Date && !isNaN(date as unknown as number)
}

export const AgencyDashboardView = ({
  paginator,
  navigate,
  agencies,
  isFetching,
  isAgenciesAdmin = false,
}: {
  isFetching: boolean
  navigate: NavigateFunction
  agencies: Agency[]
  isAgenciesAdmin?: boolean
  paginator?: Paginator
}) => {
  const getTranslation = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const [toggleView, setToggleView] = useState<'grid' | 'list'>((searchParams.get('mode') as 'grid' | 'list') || 'grid')
  /* prepared filters for when clicking apply */
  const [preparedFilters, setPreparedFilters] = useState<PreparedFilters>({
    range: [],
    createdBy: '',
  })
  const [activeFilterCount, setActiveFilterCount] = useState<number>(0)
  /* get / set the date picker range */
  const setSearchTerm = useApiSearch('name', isFetching)

  const [searchValue, setSearchValue] = useState<string>(searchParams.get('search[name]') ?? '')
  const [userNameValue, setUserNameValue] = useState<string>('')
  const [dateRange, setDateRange] = useState<string[]>([])
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false)
  const [selectedAgency, setSelectedAgency] = useState<{ id: string; name: string }>({ id: '', name: '' })
  const AllUsers = 'All users'
  const { data: agencyCreators } = useGetAgencyCreators()
  const [{ sortAsc, activeSortType }, handleSortFilters] = useAgencyApiSortFilters('name')

  const handleToggle = (value: 'grid' | 'list') => {
    setSearchParams(prev => {
      prev.set('view', SettingsTabs.AGENCIES)
      prev.set('mode', value)
      return prev
    })
    setToggleView(value)
  }

  /* store all the userNames for the filters */
  const userNameOptions = useMemo(
    () => [AllUsers].concat([...new Set(agencyCreators.map(item => item?.name))].sort((a, b) => a.localeCompare(b))),
    [agencyCreators],
  )

  const handleSortDisplayData = useCallback(
    (type: sortType) => {
      handleSortFilters(type)
    },
    [handleSortFilters],
  )

  const handleSearchChange = useCallback(
    (value: string) => {
      setSearchValue(value)
      setSearchTerm(value)
    },
    [setSearchTerm],
  )

  const handleFetchByFilters = async () => {
    const { createdBy, range } = preparedFilters
    if (createdBy && createdBy !== AllUsers) {
      setSearchParams(prev => {
        prev.set('filter[createdBy]', createdBy)
        prev.set('page', '1')
        return prev
      })
    } else {
      setSearchParams(prev => {
        prev.delete('filter[createdBy]')
        return prev
      })
    }
    setSearchParams(prev => {
      prev.delete('filter[createdAt>]')
      prev.delete('filter[createdAt<]')
      return prev
    })
    if (range.length) {
      const from = new Date(range?.[0]?.setHours(0, 0, 1, 0))
      const to = new Date(range?.[1]?.setHours(23, 59, 0, 0))
      if (isValidDate(from) && isValidDate(to)) {
        setSearchParams(prev => {
          prev.set(
            'filter[createdAt>]',
            new Intl.DateTimeFormat('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(range[0]),
          )
          prev.set(
            'filter[createdAt<]',
            new Intl.DateTimeFormat('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(range[1]),
          )
          prev.set('page', '1')
          return prev
        })
      }
    }
  }

  const handleFilterAddedBy = (e: WppSelectCustomEvent<SelectChangeEventDetail>) => {
    const value = e?.detail?.value || ''
    const userNameValue = value
    const createdByEmail = value === AllUsers ? '' : agencyCreators.find(item => item.name === value)?.email || ''

    /* if select all users reset */
    setPreparedFilters({ ...preparedFilters, createdBy: createdByEmail })
    setUserNameValue(userNameValue)
  }

  const handleFilterClearRange = (_event: WppDatepickerCustomEvent<DatePickerClearEventDetail>) =>
    setPreparedFilters({ ...preparedFilters, range: [] })

  const handleFilterCreatedRange = (event: WppDatepickerCustomEvent<DatePickerEventDetail>) => {
    const range = (event?.detail?.date as Date[]) || []
    setPreparedFilters({ ...preparedFilters, range })
    setDateRange([new Intl.DateTimeFormat('en-GB').format(range[0]), new Intl.DateTimeFormat('en-GB').format(range[1])])
  }

  const handleApplyFilters = () => {
    /* filter the data */
    handleFetchByFilters()
  }

  /* reset everything and default to initial agencies set */
  const handleResetFilters = () => {
    handleSortFilters('name', true)
    setUserNameValue('')
    setDateRange([])
    setPreparedFilters({ range: [], createdBy: '' })
  }

  const handleEditAgencyClick = (agencyId: string) =>
    navigate(`/rfi-helper-tool/settings/agency-dashboard/${agencyId}`, { state: { toggleView } })

  const handleDeleteAgencyClick = (agencyId: string, name: string) => {
    setSelectedAgency({ id: agencyId, name })
    setIsEditModalOpen(true)
  }

  useEffect(() => {
    if (!searchParams.get('view')) {
      setSearchParams(prev => {
        prev.set('view', SettingsTabs.AGENCIES)
        prev.set('mode', toggleView)
        return prev
      })
    }
    if (!searchParams.get('page')) {
      setSearchParams(prev => {
        prev.set('page', '1')
        return prev
      })
    }

    setUserNameValue(searchParams.get('filter[createdBy]') || '')
  }, [searchParams, setSearchParams, toggleView])

  useEffect(() => {
    let range: Date[] = []
    if (searchParams.get('filter[createdAt>]') && searchParams.get('filter[createdAt<]')) {
      range = [
        new Date(searchParams.get('filter[createdAt>]') || ''),
        new Date(searchParams.get('filter[createdAt<]') || ''),
      ]
      setDateRange([
        new Intl.DateTimeFormat('en-GB').format(range[0]),
        new Intl.DateTimeFormat('en-GB').format(range[1]),
      ])
    } else {
      setDateRange([])
    }
    setPreparedFilters(prev => ({ ...prev, createdBy: searchParams.get('filter[createdBy]') || '', range: range }))
    setUserNameValue(agencyCreators.find(item => item.email === searchParams.get('filter[createdBy]'))?.name || '')
  }, [searchParams, agencyCreators])

  useEffect(() => {
    setActiveFilterCount(Object.values(preparedFilters).reduce((accu, item) => accu + Number(item.length > 0), 0))
  }, [preparedFilters])

  const isEmpty =
    agencies.length === 0 &&
    !isFetching &&
    (preparedFilters.createdBy.length > 0 || preparedFilters.range.length > 0 || searchValue.length > 0)

  return (
    <div className="mt-7">
      <AgencyFilterGroup
        hideSorts={toggleView === 'list'}
        handleSearchChange={handleSearchChange}
        navigate={navigate}
        handleToggle={handleToggle}
        toggleView={toggleView}
        filterCount={activeFilterCount}
        handleResetFilters={handleResetFilters}
        handleApplyFilters={handleApplyFilters}
        handleSortFilter={handleSortDisplayData}
        disabled={isFetching}
        isAsc={sortAsc}
        activeSortType={activeSortType}
        isAgenciesAdmin={isAgenciesAdmin}
        searchValue={searchValue}
        filters={
          <>
            <WppSelect
              aria-label="Select Users"
              title="Added By"
              placeholder="Select Users"
              labelConfig={{
                text: 'Added By',
              }}
              onWppChange={handleFilterAddedBy}
              value={userNameValue}
            >
              {userNameOptions.map((item: string) => (
                <WppListItem key={item} value={item}>
                  <p slot="label">{item}</p>
                </WppListItem>
              ))}
            </WppSelect>

            <WppDatepicker
              labelConfig={{
                text: 'Date created',
              }}
              range
              onWppDateClear={handleFilterClearRange}
              onWppChange={handleFilterCreatedRange}
              className={style.datePicker}
              value={dateRange}
            />
          </>
        }
      />
      {isFetching && (
        <div className="flex flex-row items-start justify-center h-52">
          <WppSpinner size="l" />
        </div>
      )}
      {isEmpty && (
        <EmptyState title={getTranslation(NO_RESULTS_TITLE)} description={getTranslation(NO_RESULTS_DESCRIPTION)} />
      )}
      {toggleView === 'grid' && !isEmpty && !isFetching && (
        <AgencyCards
          paginator={paginator!}
          agencies={agencies}
          handleEditAgencyClick={handleEditAgencyClick}
          handleDeleteAgencyClick={handleDeleteAgencyClick}
        />
      )}

      {toggleView === 'list' && !isEmpty && !isFetching && (
        <AgencyAgGrid
          paginator={paginator!}
          agencies={agencies}
          handleEditAgencyClick={handleEditAgencyClick}
          handleDeleteAgencyClick={handleDeleteAgencyClick}
          handleSortFilter={(type: sortType, sortAsc: boolean) => handleSortFilters(type, sortAsc)}
          sortAsc={sortAsc}
          sortType={activeSortType}
        />
      )}
      <WppModal open={isEditModalOpen} onWppModalCloseComplete={() => setIsEditModalOpen(false)} size="s">
        <AgencyDeleteModal
          agencyId={selectedAgency?.id || ''}
          name={selectedAgency?.name || ''}
          handleModalClose={() => {
            setIsEditModalOpen(false)
          }}
        />
      </WppModal>
    </div>
  )
}
