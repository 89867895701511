// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aWoWM::part(button){border-top-right-radius:0;border-bottom-right-radius:0}.x723n::part(button){border-top-left-radius:0;border-bottom-left-radius:0}.UGUXo::part(button){background-color:#e0ebff}`, "",{"version":3,"sources":["webpack://./src/pages/settings/useCases/useCasesFilterGroup/useCasesFilterGroup.module.scss"],"names":[],"mappings":"AAAA,qBACE,yBAAA,CACA,4BAAA,CAEF,qBACE,wBAAA,CACA,2BAAA,CAEF,qBACE,wBAAA","sourcesContent":[".lButton::part(button) {\n  border-top-right-radius: 0;\n  border-bottom-right-radius: 0;\n}\n.rButton::part(button) {\n  border-top-left-radius: 0;\n  border-bottom-left-radius: 0;\n}\n.selected::part(button) {\n  background-color: #e0ebff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lButton": `aWoWM`,
	"rButton": `x723n`,
	"selected": `UGUXo`
};
export default ___CSS_LOADER_EXPORT___;
