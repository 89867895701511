import { SelectChangeEventDetail } from '@wppopen/components-library'
import { WppModal, WppSpinner, WppSelect, WppCheckbox } from '@wppopen/components-library-react'
import { useState, useEffect, useCallback } from 'react'
import { NavigateFunction, useSearchParams } from 'react-router-dom'

import { Paginator } from 'api/common/types'
import EmptyState from 'components/EmptyState'
import { useApiSearch } from 'hooks/useApiSearch'
import { useApiSortFilters } from 'hooks/useApiSortFilters'
import { useTranslation } from 'hooks/useTranslation'
import { SettingsTabs } from 'pages/settings/Settings'
import { FetchUseCasesFiltersParams, UseCase } from 'types/use-cases/useCase'
import { NO_RESULTS_TITLE, NO_RESULTS_DESCRIPTION } from 'utils/translations'

import { UseCaseDeleteModal } from '../UseCaseDeleteModal'
import { UseCasesAgGrid } from '../useCasesAgGrid'
import { UseCasesCards } from '../useCasesCards/UseCasesCards'
import { sortType } from '../useCasesFilterGroup/types'
import { UseCasesFilterGroup } from '../useCasesFilterGroup/UseCasesFilterGroup'

export interface Props {
  isFetching: boolean
  navigate: NavigateFunction
  useCases: UseCase[]
  paginator: Paginator
}

export const UseCasesDashboardView = ({ navigate, useCases, isFetching, paginator }: Props) => {
  const getTranslation = useTranslation()
  const setSearchTerm = useApiSearch('projectName', isFetching)
  const [{ sortAsc, activeSortType }, handleSortFilters] = useApiSortFilters<sortType>('createdAt', false)
  const [searchParams, setSearchParams] = useSearchParams()
  const handleEditClick = (id: string) => {
    navigate(`/rfi-helper-tool/settings/use-cases/${id}`)
  }
  const [toggleView, setToggleView] = useState<'grid' | 'list'>((searchParams.get('mode') as 'grid' | 'list') || 'grid')
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [id, setId] = useState('')
  const [name, setname] = useState('')

  const handleDeleteClick = async (id: string, name?: string) => {
    setId(id)
    if (name) {
      setname(name)
    }
    setIsDeleteModalOpen(true)
  }

  const handleToggle = (value: 'grid' | 'list') => {
    setSearchParams(prev => {
      prev.set('view', SettingsTabs.USE_CASES)
      prev.set('mode', value)
      return prev
    })
    setToggleView(value)
  }

  const [searchValue, setSearchValue] = useState<string>(searchParams.get('search[projectName]') ?? '')
  const [isDefaultState, setIsDefaultState] = useState<boolean>(true)
  const [numActiveFilters, setNumActiveFilters] = useState<number>(0)

  const [isConfidentialValue, setIsConfidentialValue] = useState<boolean>(false)
  const [clientSelectValue, setClientSelectValue] = useState<string>('')
  const [agencieSelectValues, setAgencieSelectValues] = useState<string[]>([])
  const [pitchTypeSelectValues, setPitchTypeSelectValues] = useState<string[]>([])
  const [marketsSelectValues, setMarketsSelectValues] = useState<string[]>([])
  const [createBySelectValue, setCreateBySelectValue] = useState<string>('')
  const [subCategoryValue, setSubCategoryValue] = useState<string>('')

  useEffect(() => {
    if (!searchParams.get('view')) {
      setSearchParams(prev => {
        prev.set('view', SettingsTabs.USE_CASES)
        prev.set('mode', toggleView)
        return prev
      })
    }
    if (!searchParams.get('page')) {
      setSearchParams(prev => {
        prev.set('page', '1')
        return prev
      })
    }
    setClientSelectValue(searchParams.get('filter[client]') ?? '')
    setIsConfidentialValue(searchParams.get('filter[isConfidential]') ? true : false)
    setAgencieSelectValues(
      searchParams.get('filter[agencyIds]') ? searchParams.get('filter[agencyIds]')!.split(',') : [],
    )
    setPitchTypeSelectValues(
      searchParams.get('filter[pitchTypes]') ? searchParams.get('filter[pitchTypes]')!.split(',') : [],
    )
    setMarketsSelectValues(
      searchParams.get('filter[marketIds]') ? searchParams.get('filter[marketIds]')!.split(',') : [],
    )
    setCreateBySelectValue(searchParams.get('filter[createdBy]') || '')

    setSubCategoryValue(searchParams.get('filter[subcategory]') || '')
  }, [searchParams, setSearchParams, toggleView])

  const calculateFilters = useCallback((): FetchUseCasesFiltersParams => {
    let activeFilters: FetchUseCasesFiltersParams = {}
    activeFilters = {
      ...activeFilters,
      'filter[markets]': marketsSelectValues.length > 0 ? marketsSelectValues.join(',') : null,
      'filter[client]': clientSelectValue.length ? clientSelectValue : null,
      'filter[pitchTypes]': pitchTypeSelectValues.length > 0 ? pitchTypeSelectValues.join(',') : null,
      'filter[agencyIds]': agencieSelectValues.length > 0 ? agencieSelectValues.join(',') : null,
      'filter[createdBy]': createBySelectValue.length ? createBySelectValue : null,
      'filter[subcategory]': subCategoryValue.length ? subCategoryValue : null,
      'filter[isConfidential]': isConfidentialValue ? 'true' : null,
    }

    return activeFilters
  }, [
    clientSelectValue,
    createBySelectValue,
    marketsSelectValues,
    pitchTypeSelectValues,
    agencieSelectValues,
    isConfidentialValue,
    subCategoryValue,
  ])

  const [activeFilters, setActiveFilters] = useState<FetchUseCasesFiltersParams>(calculateFilters())

  const handleResetFilters = () => {
    handleCancelFilters(true)
    setTimeout(() => {
      setIsDefaultState(true)
    }, 10)
  }

  const handleApplyFilters = () => {
    applyAllFilters(calculateFilters())
  }

  const applyAllFilters = (activeFilters: FetchUseCasesFiltersParams) => {
    setTimeout(() => {
      setSearchParams(prev => {
        prev.set('page', '1')
        return prev
      })
      setActiveFilters(activeFilters)
    }, 0)
  }

  useEffect(() => {
    for (const [key, value] of Object.entries(activeFilters)) {
      setSearchParams(prev => {
        if (value) {
          prev.set(key, value)
        } else {
          prev.delete(key)
        }
        return prev
      })
    }

    const numFilters = Object.values(activeFilters).filter(value => value !== null).length
    setNumActiveFilters(numFilters)
    setIsDefaultState(numFilters === 0)
  }, [activeFilters, setSearchParams])

  const handleCancelFilters = (reset = false) => {
    if (reset) {
      setMarketsSelectValues([])
      setClientSelectValue('')
      setPitchTypeSelectValues([])
      setCreateBySelectValue('')
      setAgencieSelectValues([])
      setSubCategoryValue('')
      setIsConfidentialValue(false)
    } else {
      setMarketsSelectValues(activeFilters['filter[markets]']?.split(',') || [])
      setClientSelectValue(activeFilters['filter[client]'] || '')
      setPitchTypeSelectValues(activeFilters['filter[pitchTypes]']?.split(',') || [])
      setCreateBySelectValue(activeFilters['filter[createdBy]'] || '')
      setSubCategoryValue(activeFilters['filter[subcategory]'] || '')
      setAgencieSelectValues(activeFilters['filter[agencyIds]']?.split(',') || [])
      setIsConfidentialValue(activeFilters['filter[isConfidential]'] ? true : false)
    }
  }

  const handleSearchChange = useCallback(
    (value: string) => {
      setSearchValue(value)
      setSearchTerm(value)
    },
    [setSearchTerm],
  )

  const handleAgencySelectChange = useCallback((e: CustomEvent<SelectChangeEventDetail>) => {
    setAgencieSelectValues(e.detail.value)
  }, [])

  const handleSortDisplayData = useCallback(
    (type: sortType) => {
      handleSortFilters(type)
    },
    [handleSortFilters],
  )

  const isEmpty = useCases.length === 0 && !isFetching && (numActiveFilters > 0 || searchValue.length > 0)

  return (
    <div className="mt-7">
      <UseCasesFilterGroup
        disabled={isFetching}
        handleToggle={handleToggle}
        handleApplyFilters={handleApplyFilters}
        handleResetFilters={handleResetFilters}
        handleCancelFilters={handleCancelFilters}
        handleSearchChange={handleSearchChange}
        filterCount={numActiveFilters}
        resetFiltersVisible={!isDefaultState}
        handleSortFilter={handleSortDisplayData}
        activeSortType={activeSortType}
        isAsc={sortAsc}
        searchValue={searchValue}
        toggleView={toggleView}
        hideSorts={true} //temporary hide sort
        filters={
          <>
            <WppCheckbox
              checked={isConfidentialValue}
              labelConfig={{
                text: 'Confidential file',
              }}
              onWppChange={e => {
                setIsConfidentialValue(e.detail.checked)
              }}
            />
            {/* <WppSelect
              aria-label="Confidentiality"
              title="Agencies"
              placeholder="Select confidentiality"
              type="single"
              labelConfig={{
                text: 'Confidentiality',
              }}
              value={isConfidentialValue ? 'Confidential' : ''}
              onWppChange={e => {
                setIsConfidentialValue(e.detail.value === 'Confidential')
              }}
            >
              <WppListItem key="use-case-confidential" value="Confidential">
                <p slot="label">Confidential</p>
              </WppListItem>
              <WppListItem key="use-case-not-confidential" value="Not confidential">
                <p slot="label">Not confidential</p>
              </WppListItem>
            </WppSelect> */}
            <WppSelect
              aria-label="Agencies"
              title="Agencies"
              placeholder="Select Agencies"
              type="multiple"
              labelConfig={{
                text: 'Agencies',
              }}
              withSearch
              withFolder
              value={agencieSelectValues}
              onWppChange={handleAgencySelectChange}
            >
              {/* {agencies?.map(item => (
                <WppListItem key={`${item.id}-agencies`} value={item.id}>
                  <p slot="label">{item.name}</p>
                </WppListItem>
              ))} */}
            </WppSelect>
            <WppSelect
              aria-label="Markets"
              title="Markets"
              placeholder="Select markets"
              type="multiple"
              labelConfig={{
                text: 'Markets',
              }}
              withSearch
              withFolder
              onWppChange={e => {
                setMarketsSelectValues(e.detail.value)
              }}
              value={marketsSelectValues}
            >
              {/* {(markets as { market: string; id: string }[])?.map(item => (
                <WppListItem key={`${item.id}-markets`} value={item.id}>
                  <p slot="label">{item.market}</p>
                </WppListItem>
              ))} */}
            </WppSelect>
            <WppSelect
              aria-label="Clients"
              title="Clients"
              placeholder="Select clients"
              type="single"
              labelConfig={{
                text: 'Clients',
              }}
              withFolder
              withSearch
              value={clientSelectValue}
              onWppChange={e => {
                const clinetValue = e.detail.value as string
                // const clientValues = e.detail.value.reduce(
                //   (accu: string[], item: { name: string; id: string } | string) => {
                //     if (typeof item === 'string') {
                //       accu.push(item)
                //     }
                //     return accu
                //   },
                //   [],
                // )
                setClientSelectValue(clinetValue)
              }}
            >
              {/* {clients?.map(item => (
                <WppListItem key={`${item.id}-clients`} value={item.id}>
                  <p slot="label">{item.name}</p>
                </WppListItem>
              ))} */}
            </WppSelect>
            <WppSelect
              aria-label="Pitch type"
              title="Pitch type"
              placeholder="Select pitch type"
              type="multiple"
              labelConfig={{
                text: 'Pitch type',
              }}
              withSearch
              value={pitchTypeSelectValues}
              withFolder
              onWppChange={e => {
                setPitchTypeSelectValues(e.detail.value)
              }}
            >
              {/* {pitches?.map(item => (
                <WppListItem key={`${item.id}-pitches`} value={item.id}>
                  <p slot="label">{item.name}</p>
                </WppListItem>
              ))} */}
            </WppSelect>
            <WppSelect
              aria-label="Created by"
              title="Created by"
              placeholder="Select created by"
              type="single"
              labelConfig={{
                text: 'Created by',
              }}
              withSearch
              withFolder
              value={createBySelectValue}
              onWppChange={e => {
                setCreateBySelectValue(e.detail.value)
              }}
              dropdownConfig={{ placement: 'top' }}
            >
              {/* {createdBys?.map(item => (
                <WppListItem key={item.email} value={item.email}>
                  <p slot="label">{item.name}</p>
                </WppListItem>
              ))} */}
            </WppSelect>
          </>
        }
      />

      {isFetching && (
        <div className="flex flex-row items-start justify-center h-52">
          <WppSpinner size="l" />
        </div>
      )}

      {isEmpty && (
        <EmptyState title={getTranslation(NO_RESULTS_TITLE)} description={getTranslation(NO_RESULTS_DESCRIPTION)} />
      )}
      {toggleView === 'grid' && !isEmpty && !isFetching && (
        <UseCasesCards
          paginator={paginator}
          useCases={useCases}
          handleEditClick={handleEditClick}
          handleDeleteClick={handleDeleteClick}
        />
      )}

      {toggleView === 'list' && !isEmpty && !isFetching && (
        <UseCasesAgGrid
          paginator={paginator!}
          items={useCases}
          handleEditClick={handleEditClick}
          handleDeleteClick={handleDeleteClick}
          handleSortFilter={(type: sortType, sortAsc: boolean) => handleSortFilters(type, sortAsc)}
          sortAsc={sortAsc}
          sortType={activeSortType}
        />
      )}

      <WppModal open={isDeleteModalOpen} onWppModalClose={() => setIsDeleteModalOpen(false)} size="s">
        <UseCaseDeleteModal
          useCaseId={id}
          name={name}
          handleModalClose={() => {
            setIsDeleteModalOpen(false)
          }}
          handleDeleteSuccess={() => {
            setIsDeleteModalOpen(false)
          }}
        />
      </WppModal>
    </div>
  )
}
