import { WppSpinner } from '@wppopen/components-library-react'
import { AgGridReact } from 'ag-grid-react'
import { useRef } from 'react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import '@wppopen/components-library/dist/collection/ag-theme-wpp.css'

import { ApiPaginator } from 'components/ApiPaginator'
import HeaderCellApiSort from 'components/Grid/HeaderCellApiSort'
import { AgTableProps } from 'pages/agency-dashboard/AgencyFilterGroup/types'
import { SettingsTabs } from 'pages/settings/Settings'
import { Agency } from 'types/agencies/agencies'

import { columnDefenitions } from './columnsDefenitions'
import { sortType } from '../AgencyFilterGroup/types'

export type TableDataItem = Agency & { user: { name: string; img: string } }

export const AgGridTable = ({
  agencies,
  paginator,
  handleEditAgencyClick,
  handleDeleteAgencyClick,
  handleSortFilter,
  sortAsc = true,
  sortType = 'name',
}: AgTableProps) => {
  const gridRef = useRef<AgGridReact<TableDataItem>>(null)

  const columnDefs = columnDefenitions(handleEditAgencyClick, handleDeleteAgencyClick, sortAsc, sortType)

  return (
    <div data-testid="ag-grid-table">
      <div className="ag-theme-wpp mb-[32] w-full" key="123">
        <AgGridReact
          ref={gridRef}
          gridId="agency-grid"
          rowData={agencies}
          columnDefs={columnDefs}
          defaultColDef={{
            headerComponent: HeaderCellApiSort,
            sortable: false,
          }}
          onRowClicked={({ data, event }) => {
            const target = event?.target as HTMLElement
            if (!target?.dataset?.contextMenu) {
              handleEditAgencyClick(data.id)
            }
          }}
          onColumnHeaderClicked={({ api, column }) => {
            const id = column.getId() as sortType
            const sort = api.getColumn(id)!.getSort() ?? 'asc'
            handleSortFilter(id, sort === 'asc')
          }}
          rowDragMultiRow
          animateRows
          rowDragManaged
          suppressPaginationPanel
          loadingOverlayComponent={() => <WppSpinner size="m" />}
          rowClass="cursor-pointer"
          rowClassRules={{
            'with-error': data => (data.data ? !!data.data.meta?.error : false),
          }}
          domLayout="autoHeight"
        />
      </div>
      <ApiPaginator paginator={paginator} viewName={SettingsTabs.AGENCIES} />
    </div>
  )
}
