import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { sortType } from './AgencyFilterGroup/types'

export type AgencyApiSortFilterReturn = [
  { activeSortType: sortType; sortAsc: boolean },
  (type: sortType, sortAsc?: boolean) => void,
]

export const useAgencyApiSortFilters = (type: sortType = 'name'): AgencyApiSortFilterReturn => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [sortAsc, setSortAsc] = useState<boolean>(true)
  const [activeSortType, setActiveSortType] = useState<sortType>(type)

  const handleSortFilter = (type: sortType, sortAscending?: boolean) => {
    const newSortAsc = sortAscending ?? !sortAsc
    setSortAsc(newSortAsc)
    setActiveSortType(type)
    setSearchParams(prev => {
      prev.set('order', `${type === 'categories' ? 'numberOfCategories' : type}:${newSortAsc ? 'ASC' : 'DESC'}`)
      return prev
    })
  }
  useEffect(() => {
    const order = searchParams.get('order')
    if (order) {
      const [sortType, sortDirection] = order.split(':')
      setActiveSortType(sortType as sortType)
      setSortAsc(sortDirection === 'ASC')
    }
  }, [searchParams])

  // return the handlers and the active sort type
  return [{ activeSortType, sortAsc }, handleSortFilter]
}
