import {
  WppIconTableSort,
  WppIconTableSortAsc,
  WppIconTableSortDesc,
  WppTypography,
} from '@wppopen/components-library-react'
import { IHeaderParams } from 'ag-grid-community'
import { FunctionComponent } from 'react'
import './headerCell.css'

export const HeaderCellApiSort: FunctionComponent<IHeaderParams> = props => {
  const handleHeaderCellClick = () => {
    if (!props.enableSorting) return
    const selectedSort = props.column.getSort()
    let nextSortType: 'asc' | 'desc' | undefined = 'asc'
    if (selectedSort === 'asc') {
      nextSortType = 'desc'
    } else {
      nextSortType = 'asc'
    }

    props.setSort(nextSortType)
  }

  return (
    <div className="ag-header-cell-comp" onClick={() => handleHeaderCellClick()}>
      <WppTypography type="s-strong">{props.displayName}</WppTypography>
      {props.enableSorting && (
        <div className="ag-sorting">
          <WppIconTableSort className="sortIcon" />
          <WppIconTableSortAsc className="ascSortIcon" />
          <WppIconTableSortDesc className="descSortIcon" />
        </div>
      )}
    </div>
  )
}

export default HeaderCellApiSort
