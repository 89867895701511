import { AnalyticsActionType } from '@wppopen/core'
import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { PaginationParams } from 'api/common/types'
import { useRfis } from 'api/queries/rfis/useRfis'
import { navigation } from 'components/SidebarCmp'
import { useSettingsPermissions } from 'hooks/useSettingsPermissions'

import EmptyProjects from './EmptyProjects'
import { ProjectDashboardView } from './ProjectDashboardView'
import { trackAnalytics, ANALYTICS_EVENTS } from '../../utils/analytics'
import { FetchRfiFiltersParams } from '@/types/rfis/rfi'

export interface SetApifilterProps {
  clientIds?: string[]
  marketIds?: string[]
  pitchTypeIds?: string[]
}
export default function HomePage() {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const projectsParams: PaginationParams<FetchRfiFiltersParams> | undefined = searchParams
    ? Object.fromEntries(searchParams?.entries())
    : undefined
  const {
    data: { data: projects, paginator } = {
      data: [],
      paginator: { page: 1, totalItems: 0, totalPages: 0, itemsPerPage: 0 },
    },
    error,
    isLoading,
    isFetching,
  } = useRfis({
    staleTime: 60 * 10 * 1000, // 10 minutes
    params: projectsParams,
  })

  const { hasAgenciesPermission: hasPermission } = useSettingsPermissions()

  const isEmpty =
    !isLoading &&
    (error || (!error && !projects.length && searchParams.size === 1 && searchParams.get('page') !== null))

  useEffect(() => {
    trackAnalytics({
      type: AnalyticsActionType.page,
      payload: ANALYTICS_EVENTS.MY_PROJECTS_PAGE_VIEW,
    })
  }, [])

  useEffect(() => {
    if (!searchParams.get('page')) {
      setSearchParams(prev => {
        prev.set('page', '1')
        return prev
      })
    }
  }, [searchParams, setSearchParams])

  if (isEmpty)
    return (
      <EmptyProjects
        agencyPermission={hasPermission}
        navigate={navigate}
        title="No Projects to Show"
        description="Create a new project to get started"
        action={{
          handleClick: () => navigate(navigation.newProject),
          cta: 'Add new',
        }}
      />
    )

  return (
    <ProjectDashboardView
      paginator={paginator}
      projects={projects}
      navigate={navigate}
      isLoading={isLoading}
      isFetching={isFetching}
      error={error}
      agencyPermission={hasPermission}
    />
  )
}
