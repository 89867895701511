import { WppSpinner } from '@wppopen/components-library-react'
import { Navigate, Outlet } from 'react-router-dom'

import { useSettingsPermissions } from 'hooks/useSettingsPermissions'

export const SettingsRoutes = () => {
  const { hasAgenciesPermission: hasPermission, isLoadingPermissions: isLoading } = useSettingsPermissions()

  if (isLoading) {
    return (
      <div className="mx-auto">
        <WppSpinner size="m" />
      </div>
    )
  }
  return hasPermission ? <Outlet /> : <Navigate to="/" />
}
