import {
  WppActionButton,
  WppIconArrow,
  WppIconCopy,
  WppSpinner,
  WppTooltip,
  WppTypography,
} from '@wppopen/components-library-react'
import clsx from 'clsx'
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import Markdown from 'react-markdown'

import { QuestionInputNew } from 'components/chat/question-input/QuestionInputNew'
import EmptyState from 'components/EmptyState'
import { LoaderProgressWithDescription } from 'components/LoaderProgressWithDescription'

import styles from './RfiSummary.module.scss'
import { SummaryRef } from './RfiSummaryPage'
import { SummaryConversation } from './SummaryConversation'
import { transformSummaryQuestions } from './utils'
import { SummaryAndChatProps } from '@/types/summaries/summary'

const bottomScrollPosition = 100
const topScrollPosition = 0

export const SummaryAndChat = forwardRef<SummaryRef, SummaryAndChatProps>((props, ref) => {
  const {
    scrollToView,
    rfiAndSummary: { summaryQuestions, isLoadingRfi, rfiError, summary },
    isAnswerLoading,
    taskStatus,
    handleSubmitQuestion,
    handleCopyToClipboard,
  } = props
  const [question, setQuestion] = useState('')
  const questionsAndAnswers = summaryQuestions.length ? transformSummaryQuestions(summaryQuestions) : []
  const [scrollDirection, setScrollDirection] = useState<'up' | 'down' | null>(null)
  const [scrollPosition, setScrollPosition] = useState<number>(0)
  const previousScrollY = useRef(0)

  const summaryContainerRef = useRef<HTMLDivElement>(null)
  const scrollToTopDivRef = useRef<HTMLDivElement>(null)
  const scrollToBottomDivRef = useRef<HTMLDivElement>(null)

  useImperativeHandle(ref, () => ({
    scrollToTopDivRef: scrollToTopDivRef.current,
    scrollToBottomDivRef: scrollToBottomDivRef.current,
    summaryContainerRef: summaryContainerRef.current,
  }))

  /*
    set scroll direction to null when the summary container is totally scrolled
    this is to remove the scroll buttons from top and bottom
  */
  useEffect(() => {
    if ([topScrollPosition, bottomScrollPosition].includes(scrollPosition) && scrollDirection) {
      setScrollDirection(null)
    }
  }, [scrollDirection, scrollPosition])

  const handleScroll = () => {
    const summaryContainer = summaryContainerRef.current
    const scrollY = summaryContainerRef.current?.scrollTop ?? 0

    const position = summaryContainer
      ? Math.ceil((summaryContainer.scrollTop / (summaryContainer.scrollHeight - summaryContainer.clientHeight)) * 100)
      : 0
    setScrollPosition(position)

    if (Math.abs(scrollY - previousScrollY.current) > 0) {
      const newScrollDir = scrollY > previousScrollY.current ? 'down' : 'up'
      setScrollDirection(newScrollDir)
      previousScrollY.current = scrollY > 0 ? scrollY : 0
    }
  }

  const hasSummary = !isLoadingRfi && !rfiError && summary
  const showInput = isLoadingRfi || (!rfiError && summary)

  return (
    <div>
      <div className="flex items-between justify-between w-full">
        <WppTypography type="xl-heading">Summarise RFI / RFP</WppTypography>
      </div>
      <div className="flex flex-col gap-3 relative mt-3 rounded-lg bg-white">
        {isLoadingRfi && (
          <div className="h-[72dvh] flex flex-col items-center justify-center gap-2">
            <WppSpinner size="l" />
            <WppTypography type="s-body">The summary is loading...</WppTypography>
          </div>
        )}

        {(!hasSummary || rfiError) && (
          <div className="h-[80dvh] flex flex-row items-center justify-center">
            <EmptyState title="No RFI/RFP Summary Available" description="There is nothing to be shown here." />
          </div>
        )}

        {hasSummary && (
          <>
            <div
              className={clsx(
                'absolute z-80 top-3 right-5 transition-opacity',
                scrollDirection === 'up' ? 'opacity-100 visible' : 'opacity-0 invisible',
              )}
              ref={scrollToTopDivRef}
            >
              <WppTooltip text="Scroll to top">
                <button
                  className="w-10 h-10 rounded-full bg-white shadow-lg flex justify-center items-center"
                  onClick={() => scrollToView('top')}
                >
                  <WppIconArrow direction="top" />
                </button>
              </WppTooltip>
            </div>
            <div
              className={clsx(
                'absolute z-80 bottom-48 right-5 transition-opacity',
                scrollDirection === 'down' ? 'opacity-100 visible' : 'opacity-0 invisible',
              )}
              ref={scrollToBottomDivRef}
            >
              <WppTooltip text="Scroll to bottom">
                <button
                  className="w-10 h-10 rounded-full bg-white shadow-lg justify-center items-center"
                  onClick={() => scrollToView('bottom')}
                >
                  <WppIconArrow direction="down" />
                </button>
              </WppTooltip>
            </div>

            <div className={clsx(styles.summaryWrapper, 'p-6')} ref={summaryContainerRef} onScroll={handleScroll}>
              <div data-qa-container>
                <WppTypography className="max-w-full" type="s-body">
                  <Markdown>{summary}</Markdown>
                </WppTypography>
                <div className={clsx('flex justify-end sticky right-0 bottom-[0.5rem] ml-auto w-1/2 h-[38px] -mt-4')}>
                  <div
                    className={clsx('bg-white shadow-md p-1 rounded-8 flex justify-between px-[3px]')}
                    data-qa-editable
                  >
                    <WppTooltip text="Copy to clipboard">
                      <WppActionButton onClick={() => handleCopyToClipboard(summary)}>
                        <WppIconCopy size="m" />
                      </WppActionButton>
                    </WppTooltip>
                  </div>
                </div>
              </div>
              {questionsAndAnswers.length > 0 &&
                questionsAndAnswers.map(item => (
                  <SummaryConversation
                    key={item.id}
                    isTaskRunning={isAnswerLoading}
                    message={item}
                    handleCopyToClipboard={handleCopyToClipboard}
                  />
                ))}
              {isAnswerLoading && (
                <div className="flex items-center justify-center mt-3 bg-[#F8F9FB] pt-4 pb-3">
                  <LoaderProgressWithDescription taskStatus={taskStatus} />
                </div>
              )}
            </div>
          </>
        )}

        {showInput && (
          <div className={clsx(styles.questionInputWrapper, 'p-6')}>
            <div className={styles.inputBorder}>
              <QuestionInputNew
                onInput={e => {
                  setQuestion(e)
                }}
                disabled={isAnswerLoading || isLoadingRfi}
                question={question}
                answerIsLoading={isAnswerLoading}
                handleSubmit={() => {
                  handleSubmitQuestion(question)
                  setQuestion('')
                }}
                placeholder="Ask a question to explore more options and interact with the chat to get the information you need. "
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
})
