import { createUseQuery } from 'api/common/createUseQuery'
import { PaginatedResponse } from 'api/common/types'
import { fetchUseCases } from 'api/fetchers/use-cases/fetchUseCases'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { UseCase } from 'types/use-cases/useCase'

export const useUseCases = createUseQuery({
  queryKey: ApiQueryKeys.USE_CASES,
  fetcher: fetchUseCases,
  selector: res =>
    res?.data ??
    ({ paginator: { page: 1, totalItems: 0, totalPages: 0, itemsPerPage: 0 }, data: [] } as PaginatedResponse<UseCase>),
})
