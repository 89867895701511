import { debounce } from '@wppopen/components-library'
import { useEffect, useState, useDeferredValue, useMemo, useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

export type ApiSearchReturn = (value: string) => void

export const useApiSearch = (searchParamName: string, isFetching?: boolean): ApiSearchReturn => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [paramName] = useState<string>(searchParamName)
  const [searchParamValue, setSearchParamValue] = useState<string>(searchParams.get(`search[${paramName}]`) ?? '')
  const valueToSearch = useDeferredValue(searchParamValue)

  const handleSearchInput = useCallback((value: string) => {
    setSearchParamValue(value)
  }, [])

  const debouncedSearch = useMemo(() => {
    return debounce(handleSearchInput, 600)
  }, [handleSearchInput])

  useEffect(() => {
    if (valueToSearch.length > 2) {
      setSearchParams(prev => {
        prev.set(`search[${paramName}]`, valueToSearch)
        return prev
      })
    } else {
      setSearchParams(prev => {
        prev.delete(`search[${paramName}]`)
        return prev
      })
    }
  }, [valueToSearch, paramName, setSearchParams])

  useEffect(() => {
    if (!isFetching && searchParams.get(`search[${paramName}]`) !== null) {
      const wppSearch = document.getElementById('search-input')
      let time: number | undefined = undefined
      if (wppSearch && wppSearch.shadowRoot) {
        time = window.setTimeout(() => {
          const input = wppSearch.shadowRoot?.querySelector('input')
          if (input) {
            input.focus()
          }
        }, 10)
        return () => {
          if (time) {
            clearTimeout(time)
          }
        }
      }
    }
  }, [searchParams, isFetching, paramName])

  return debouncedSearch
}
