import { Paginator } from 'api/common/types'
import { ApiPaginator } from 'components/ApiPaginator'
import FileCmp from 'pages/relevant-notes/FileCmp'
import { SettingsTabs } from 'pages/settings/Settings'
import { UseCase } from 'types/use-cases/useCase'

export interface Props {
  useCases: UseCase[]
  paginator: Paginator
  handleEditClick: (id: string) => void
  handleDeleteClick: (id: string) => void
}

export const UseCasesCards = ({ useCases, paginator, handleEditClick, handleDeleteClick }: Props) => {
  return (
    <>
      <div className="flex flex-row flex-wrap gap-y-4 gap-x-[2%]">
        {useCases.map((useCase: UseCase, idx: number) => (
          <FileCmp
            key={idx}
            useCase={useCase}
            onClick={() => handleEditClick(useCase.id)}
            handleDeleteClick={handleDeleteClick}
            isAdmin={true}
          />
        ))}
      </div>
      <ApiPaginator paginator={paginator} viewName={SettingsTabs.USE_CASES} />
    </>
  )
}
