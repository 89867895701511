// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-is-edit=true] .P7cK0{display:none}.uTRRK{opacity:0}.YAun7{color:var(--button-destructive-secondary-text-color)}`, "",{"version":3,"sources":["webpack://./src/pages/agency-edit-detail/agencyEditDetail.module.scss"],"names":[],"mappings":"AAAA,2BACE,YAAA,CAEF,OACE,SAAA,CAGF,OACE,oDAAA","sourcesContent":["[data-is-edit='true'] .editButton {\n  display: none;\n}\n.hideHeading {\n  opacity: 0;\n}\n\n.dangerColor {\n  color: var(--button-destructive-secondary-text-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editButton": `P7cK0`,
	"hideHeading": `uTRRK`,
	"dangerColor": `YAun7`
};
export default ___CSS_LOADER_EXPORT___;
