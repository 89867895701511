import { PaginationChangeEventDetail } from '@wppopen/components-library'
import { WppPagination } from '@wppopen/components-library-react'
import { useSearchParams } from 'react-router-dom'

import { Paginator } from 'api/common/types'

export interface ApiPaginatorProps {
  paginator: Paginator
  viewName?: string
}

export const ApiPaginator = ({ paginator, viewName }: ApiPaginatorProps) => {
  const [, setSearchParams] = useSearchParams()
  const { page: currPage, itemsPerPage: paginationPageSize, totalItems } = paginator

  const handlePaginationChange = async (event: CustomEvent<PaginationChangeEventDetail>) => {
    const { itemsPerPage, page } = event.detail
    if (paginationPageSize !== itemsPerPage) {
      setSearchParams(prev => {
        if (viewName) {
          prev.set('view', viewName)
        }
        prev.set('itemsPerPage', itemsPerPage.toString())
        return prev
      })
    }
    if (currPage !== page) {
      setSearchParams(prev => {
        if (viewName) {
          prev.set('view', viewName)
        }
        prev.set('page', page.toString())
        return prev
      })
    }
  }

  return (
    <>
      {(totalItems > paginationPageSize || totalItems > 9) && (
        <WppPagination
          count={totalItems}
          itemsPerPage={[9, 12, 15, 30, 60, 90]}
          activePageNumber={currPage}
          pageSelectThreshold={9}
          data-testid="pagination-list"
          selectedItemPerPage={paginationPageSize}
          onWppChange={handlePaginationChange}
          className="mt-6"
        />
      )}
    </>
  )
}
