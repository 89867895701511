import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

export type ApiSortFilterReturn<T> = [{ activeSortType: T; sortAsc: boolean }, (type: T, sortAsc?: boolean) => void]

export const useApiSortFilters = <T>(type: T, sortAscending?: boolean): ApiSortFilterReturn<T> => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [sortAsc, setSortAsc] = useState<boolean>(sortAscending ?? true)
  const [activeSortType, setActiveSortType] = useState<T>(type)

  const handleSortFilter = (type: T, sortAscending?: boolean) => {
    const newSortAsc = sortAscending ?? !sortAsc
    setSortAsc(newSortAsc)
    setActiveSortType(type)
    setSearchParams(prev => {
      prev.set('order', `${type}:${newSortAsc ? 'ASC' : 'DESC'}`)
      return prev
    })
  }
  useEffect(() => {
    const order = searchParams.get('order')
    if (order) {
      const [sortType, sortDirection] = order.split(':')
      setActiveSortType(sortType as T)
      setSortAsc(sortDirection === 'ASC')
    }
  }, [searchParams])

  // return the handlers and the active sort type
  return [{ activeSortType, sortAsc }, handleSortFilter]
}
