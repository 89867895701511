import { createUseQuery } from 'api/common/createUseQuery'
import { fetchRfisMeta } from 'api/fetchers/rfis/fetchRfisMeta'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { RfiMeta } from 'types/rfis/rfi'

export const useRfisMeta = createUseQuery({
  queryKey: ApiQueryKeys.RFIS_META,
  fetcher: fetchRfisMeta,
  selector: res => res?.data ?? ({} as RfiMeta),
})
