import { createUseQuery } from 'api/common/createUseQuery'
import { PaginatedResponse } from 'api/common/types'
import { fetchRfis } from 'api/fetchers/rfis/fetchRfis'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { ProjectDetails } from 'types/rfis/rfi'

export const useRfis = createUseQuery({
  queryKey: ApiQueryKeys.RFIS,
  fetcher: fetchRfis,
  selector: res =>
    res?.data ??
    ({
      paginator: { page: 1, totalItems: 0, totalPages: 0, itemsPerPage: 0 },
      data: [],
    } as PaginatedResponse<ProjectDetails>),
})
