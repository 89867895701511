import { InputChangeEventDetail } from '@wppopen/components-library'
import { WppInputCustomEvent } from '@wppopen/components-library/dist/types/components'
import {
  WppActionButton,
  WppButton,
  WppFilterButton,
  WppIconAdd,
  WppIconGear,
  WppIconReset,
  WppInput,
  WppListItem,
  WppMenuContext,
  WppSegmentedControl,
  WppSegmentedControlItem,
  WppSideModal,
  WppSortButton,
} from '@wppopen/components-library-react'
import { AnalyticsActionType } from '@wppopen/core'
import { useState } from 'react'

import { navigation } from 'components/SidebarCmp'
import { ControlValues } from 'pages/home/ProjectDashboardView'

import { ProjectsFilterGroupProps } from './types'
import { ANALYTICS_EVENTS, trackAnalytics } from '../../utils/analytics'

export const ProjectsFilterGroup = ({
  navigate,
  agencyPermission,
  handleResetFilters,
  handleSearchChange,
  handleApplyFilters,
  handleCancelFilters,
  agencyDropdown,
  disabled = false,
  filters = null,
  controlValue,
  setFilterControlValue,
  numOfSelectedFilters,
  resetFiltersVisible,
  handleSortFilter,
  isAsc,
  activeSortType,
  searchValue,
}: ProjectsFilterGroupProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const onSearchChange = (e: WppInputCustomEvent<InputChangeEventDetail>) => handleSearchChange(e?.detail?.value || '')

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const [isAction, setIsAction] = useState(false)

  const handleCancel = () => {
    setIsAction(true)
    handleCloseModal()
    handleCancelFilters()
    setFilterControlValue(ControlValues.ALL)
  }

  const handleCloseModal = () => setIsModalOpen(false)
  const handleReset = () => {
    handleResetFilters()
  }
  const handleApply = () => {
    setIsAction(true)
    handleApplyFilters()
    handleCloseModal()
  }

  const handleNewProjectClick = () => {
    navigate(navigation.newProject)
    trackAnalytics({
      type: AnalyticsActionType.action,
      payload: ANALYTICS_EVENTS.NEW_PROJECT_BUTTON_CLICK_ACTION,
    })
  }

  const closeComplete = () => {
    if (!isAction) {
      handleCancelFilters()
    }
    setIsAction(false)
  }

  return (
    <>
      <div className="flex max-w-[80%]">
        <div className="flex gap-4 basis-1/2">
          <WppInput
            className="min-w-60"
            disabled={disabled}
            size="s"
            placeholder="Search"
            aria-label="Search agencies"
            id="search-input"
            onWppChange={onSearchChange}
            value={searchValue}
          />
          {agencyDropdown && agencyDropdown}
          <WppFilterButton name="Filters" onClick={handleOpenModal} disabled={disabled} counter={numOfSelectedFilters}>
            Filters
          </WppFilterButton>
        </div>
      </div>

      <div className="flex gap-4 ml-auto">
        <WppMenuContext className="mr-4">
          <WppSortButton slot="trigger-element" name="Sorts" disabled={disabled}>
            Sort
          </WppSortButton>
          <div>
            <WppListItem onWppChangeListItem={() => handleSortFilter('projectName', false)}>
              <p slot="label">By Name {activeSortType === 'projectName' && !isAsc ? '(Z-A)' : '(A-Z)'}</p>
            </WppListItem>
            <WppListItem onWppChangeListItem={() => handleSortFilter('createdAt', false)}>
              <p slot="label">By Date {activeSortType === 'createdAt' && !isAsc ? ' (New-Old)' : ' (Old-New)'}</p>
            </WppListItem>
          </div>
        </WppMenuContext>
        {agencyPermission && (
          <WppActionButton variant="secondary" onClick={() => navigate(navigation.settings)}>
            <WppIconGear slot="icon-start" />
          </WppActionButton>
        )}
        <WppButton variant="primary" onClick={() => handleNewProjectClick()} size="s">
          <WppIconAdd slot="icon-start" />
          New Project
        </WppButton>

        <WppSideModal
          size="s"
          open={isModalOpen}
          onWppSideModalClose={handleCancel}
          onWppSideModalCloseComplete={closeComplete}
          withBackButton
        >
          <>
            <h3 slot="header">Filter</h3>
            <div slot="body" className="flex flex-col gap-6">
              <WppSegmentedControl
                value={controlValue}
                onWppChange={e => setFilterControlValue((String(e.detail.value) as ControlValues) || ControlValues.ALL)}
              >
                <WppSegmentedControlItem value={ControlValues.ALL}>{ControlValues.ALL}</WppSegmentedControlItem>
                <WppSegmentedControlItem value={ControlValues.MY_PROJECTS} disabled={disabled}>
                  {ControlValues.MY_PROJECTS}
                </WppSegmentedControlItem>
                <WppSegmentedControlItem value={ControlValues.SHARED_WITH_ME}>
                  {ControlValues.SHARED_WITH_ME}
                </WppSegmentedControlItem>
              </WppSegmentedControl>
              {filters}
            </div>
            <div slot="actions" className="flex justify-between">
              {resetFiltersVisible && (
                <WppActionButton variant="primary" onClick={handleReset}>
                  Reset <WppIconReset slot="icon-start" />
                </WppActionButton>
              )}

              <WppButton variant="secondary" size="s" onClick={handleCancel} className="ml-auto mr-3">
                Cancel
              </WppButton>
              <WppButton variant="primary" size="s" onClick={handleApply}>
                Apply
              </WppButton>
            </div>
          </>
        </WppSideModal>
      </div>
    </>
  )
}
