import { WppSkeleton, WppTypography } from '@wppopen/components-library-react'
import { useOs } from '@wppopen/react'
import { lazy, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useUpdateRfi } from 'api/mutations/rfis/useUpdateRfi'
import { usePitchTypes } from 'api/queries/pitch-types/usePitchTypes'
import { useRfi } from 'api/queries/rfis/useRfi'
import { queryClient } from 'app/Root'
import { UserCollaboration } from 'components/users/UserCollaboration'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useToast } from 'hooks/useToast'
import { UpdateRfiProps } from 'types/rfis/rfi'
import { GetRfiReturn, IUser, Role } from 'types/users/userList'
import { agenciesAsString, useGetMarketsByIds, useGetPitchTypesByIds } from 'utils/projectUtils'

import { ProjectDetail } from './ProjectDetail'

const ProjectMembersGrid = lazy(() =>
  import('./ProjectMembersGrid').then(module => ({ default: module.ProjectMembersGrid })),
)

export const ProjectDetailPage = () => {
  const { projectId } = useParams()
  const { data: project, isLoading: isLadingRfi } = useRfi({
    params: { rfiId: projectId || '' },
  })
  const [initUsers, setInitUsers] = useState<IUser[]>([])
  const { mutateAsync: updateProject, isPending: isUpdatingRfi } = useUpdateRfi({
    onSuccess: data => {
      queryClient.setQueryData([ApiQueryKeys.RFI], data)
    },
  })

  const { isFetching, isLoading } = usePitchTypes()
  const { showToast } = useToast()
  const {
    osContext: { userDetails },
  } = useOs()
  const isFetchingPitchTypes = isFetching || isLoading

  const creator = project?.createdBy
  const currentUser = {
    id: userDetails.id,
    firstname: userDetails.firstname,
    lastname: userDetails.lastname,
    avatarUrl: userDetails.avatarUrl ?? '',
    role: Role.Owner,
    email: userDetails.email,
  }

  const userRole = project?.projectMembers?.find(member => member.member === userDetails.email)?.role
  const isProjectOwner = userRole === Role.Owner
  const pitchType = useGetPitchTypesByIds(project?.pitchTypeIds)

  const markets = useGetMarketsByIds(project?.marketIds)
  const agencies = agenciesAsString(project?.agencies)

  const fetching = isFetchingPitchTypes || isLadingRfi
  const members = project?.projectMembers

  /* methods */
  const handleUpdateProject = async (updatedUsers: GetRfiReturn[], prevUpdatedUsers: GetRfiReturn[]) => {
    if (!project) return
    const currentMember = {
      member: currentUser.email,
      role: currentUser.role || Role.Owner,
    }
    const projectMembers = [currentMember, ...updatedUsers].map(user => ({
      member: user?.member || '',
      role: user?.role || Role.Contributor,
    }))

    const transformProjectPayload: UpdateRfiProps = {
      fileKey: '',
      id: project.id,
      projectName: project.projectName,
      client: project.clientId,
      pitchTypeIds: project.pitchTypeIds,
      agencies:
        project.agencies?.map(agency => ({
          id: agency.id,
        })) || [],
      activeStatus: project.activeStatus,
      marketIds: project.marketIds,
      projectMembers,
    }
    if (!project || JSON.stringify(updatedUsers) === JSON.stringify(prevUpdatedUsers)) return
    try {
      showToast({
        message: 'Updating project. Please wait...',
        type: 'information',
        duration: 1000,
      })
      await updateProject(transformProjectPayload)
      const addOrRemovedUsers = updatedUsers.length - prevUpdatedUsers.length

      if (prevUpdatedUsers.length !== updatedUsers.length) {
        showToast({
          message: `${addOrRemovedUsers > 0 ? 'Added' : 'Removed'} ${Math.abs(addOrRemovedUsers)} user(s)`,
          type: 'success',
          duration: 1500,
        })
      } else {
        showToast({
          message: 'Project updated successfully',
          type: 'success',
          duration: 1500,
        })
      }
      queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.RFIS] })
    } catch (error) {
      showToast({
        message: 'Error updating project',
        type: 'error',
      })
      console.log('Error updating project', error)
    }
  }

  useEffect(() => {
    if (creator && project) {
      const mappedProjectMembers =
        project?.projectMembers?.map(member => ({
          id: Math.random().toString(),
          firstname: member.memberDetail.name.split(' ')?.[0],
          lastname: member.memberDetail.name.split(' ')?.[1],
          avatarUrl: member.memberDetail.imgThumbnail || member.memberDetail.img || '',
          email: member.memberDetail.email,
          role: { id: Math.random().toString(), name: member.role as Role },
        })) || []
      if (mappedProjectMembers) {
        setInitUsers(mappedProjectMembers)
      }
    }
  }, [creator, project])

  const details = [
    { name: 'Client', value: project?.clientName || '' },
    { name: 'Pitch', value: pitchType || '' },
    { name: 'Markets', value: Array.isArray(markets) ? markets.map(m => m.market).join(', ') : markets || '' },
    { name: 'Agency', value: agencies },
  ]

  if (fetching) {
    return (
      <div className="mt-3 bg-[#FFFFFF] rounded-lg">
        <div className="flex gap-4 flex-wrap p-6 pb-2">
          {details.map(detail => (
            <ProjectDetail fetching {...detail} key={detail.name} />
          ))}
        </div>
        {/* Team Members */}
        <div className="flex gap-4 flex-wrap p-6 pt-2">
          <WppTypography type="l-strong">Team Members</WppTypography>
          <WppSkeleton width="100%" height={80} />
          <WppSkeleton width="100%" height={80} />
          <WppSkeleton width="100%" height={80} />
        </div>
      </div>
    )
  }

  return (
    <div data-testid="projectDetails">
      <WppTypography type="xl-heading">{project?.projectName || 'Project Details'}</WppTypography>
      <div className="mt-3 bg-[#FFFFFF] rounded-lg">
        <div className="flex gap-4 flex-wrap p-6 pb-2">
          {details.map(detail => (
            <ProjectDetail fetching={false} {...detail} key={detail.name} />
          ))}
        </div>
        {/* is owner */}
        {project && isProjectOwner && (
          <div className="flex gap-4 p-6 pb-2 flex-col pt-4 bg-[#FFFFFF]">
            {creator && (
              <UserCollaboration
                onUsersUpdated={handleUpdateProject}
                initUsers={initUsers}
                snug
                disabledInputsCondition={isUpdatingRfi}
                isTaskRunning={isUpdatingRfi}
                creator={creator}
              />
            )}
          </div>
        )}
        {project && !isProjectOwner && (
          <div className="flex gap-4 flex-wrap p-6 pt-2">
            <WppTypography type="l-strong">Team Members</WppTypography>
            <ProjectMembersGrid members={members || []} />
          </div>
        )}
      </div>
    </div>
  )
}
