interface TranslationProps {
  [key: string]: string
}

export const NO_RESULTS_TITLE = 'noResultsTitle'
export const NO_RESULTS_DESCRIPTION = 'noResultsDescription'

export const TRANSLATIONS: TranslationProps = {
  [NO_RESULTS_TITLE]: 'No Results',
  [NO_RESULTS_DESCRIPTION]: 'Try searching by different keyword or set different filters.',
}
